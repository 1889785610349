import type { ActivatedRouteSnapshot } from '@angular/router';
import type { ArchivingStatus } from '@features/partners/builder/settings/platform-access/archiving/archiving.component';
import type { Observable } from 'rxjs';

import { inject } from '@angular/core';
import { ArchivingWebservice } from '@webservices/archiving/archiving.webservice';
import { filter, of, switchMap } from 'rxjs';

export function archivingResolver(route: ActivatedRouteSnapshot): Observable<ArchivingStatus> {
  const archivingWebservice = inject(ArchivingWebservice);
  const partnerId$ = of(route.paramMap.get('partnerId')).pipe(filter(Boolean));

  return partnerId$.pipe(switchMap((partnerId) => archivingWebservice.get(partnerId)));
}
